import React from 'react';
import { ContentfulContactMethod } from '../../../graphql-types';

import * as styles from './ContactMethods.module.scss';
import Card from '@components/Card';
import TextLink from '../TextLink/TextLink';

type ContactMethodsProps = React.HTMLProps<HTMLDivElement> & {
  methods: ContentfulContactMethod[];
};

function ContactMethods({ methods }: ContactMethodsProps) {
  return (
    <div className={styles.ctaCards}>
      {methods.map(c => {
        const isChatLink = c.link === 'OpenChat';
        const onClick = isChatLink
          ? e => {
              e.preventDefault();
              window.zE('messenger', 'open');
            }
          : undefined;
        const href = isChatLink ? undefined : c.link;
        return (
          <Card className={styles.card} key={c.title} small theme="white">
            <h2>{c.title}</h2>
            <p>{c.description?.description}</p>
            <div>
              <TextLink
                to={href}
                role={isChatLink ? 'button' : undefined}
                onClick={onClick}
                aria-label={c.linkText}
                className={styles.primaryLink}
              >
                {c.linkText}
              </TextLink>
              {c.extraLinkText && (
                <div>
                  <TextLink
                    to={c.extraLink}
                    aria-label={c.extraLinkText}
                    className={styles.secondaryLink}
                  >
                    {c.extraLinkText}
                  </TextLink>
                </div>
              )}
            </div>
          </Card>
        );
      })}
    </div>
  );
}

export default ContactMethods;
